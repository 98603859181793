<template>
	<ws-view :class="$route.name">
		<ws-empty-state>
			<template #icon><ws-icon icon="signal-0"></ws-icon> </template>
			<template #header>{{ $t("router.404.header") }}</template>
			<template #description>{{ $t("router.404.description") }}</template>
			<template #button>
				<ws-button is-primary @click="goBack()">
					{{ $t("back") }}
				</ws-button>
			</template>
		</ws-empty-state></ws-view
	>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();
const goBack = () => {
	router.push("/");
};
</script>
